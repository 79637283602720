class AdminRole {
  static SUPER_ADMIN = 'SUPER_ADMIN';
  static FINANCE = 'FINANCE';
  static OPERATION = 'OPERATION';
  static SUPERVISOR = 'SUPERVISOR';
  static RISK = 'RISK';
  static MARKETING = 'MARKETING';

  static getStr(en, role = null) {
    switch (en) {
      case AdminRole.SUPER_ADMIN:
        return 'Admin Super';
      case AdminRole.FINANCE:
        return 'Keuangan';
      case AdminRole.OPERATION:
        return 'Pengembangan';
      case AdminRole.SUPERVISOR:
        if (role) return "Manajer" 
        return 'Pemasaran';
      case AdminRole.RISK:
        return 'Legal';
      case AdminRole.MARKETING:
        return 'Customer Service';
      default:
        return 'Admin Super';
    }
  }
}

const AdminRoleOpts = [
  { value: AdminRole.SUPER_ADMIN, label: AdminRole.getStr('SUPER_ADMIN') },
  { value: AdminRole.FINANCE, label: AdminRole.getStr('FINANCE') },
  { value: AdminRole.OPERATION, label: AdminRole.getStr('OPERATION') },
  { value: AdminRole.SUPERVISOR, label: AdminRole.getStr('SUPERVISOR') },
  { value: AdminRole.RISK, label: AdminRole.getStr('RISK') },
  { value: AdminRole.MARKETING, label: AdminRole.getStr('MARKETING') },
];

export { AdminRole, AdminRoleOpts };
