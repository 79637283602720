import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const LIST_URL = "/approvals/list";
export const CREATE_URL = "/approvals/create";
export const BLOCK_URL = id => `/approvals/${id}/block`;
export const COMPANY_BLOCK_URL = id => `/companies/${id}/block`;
export const COMPANY_UNBLOCK_URL = id => `/companies/${id}/unblock`;
export const DETAIL_URL = id => `/approvals/${id}/detail`;
export const APPROVE_URL = id => `/approvals/${id}/approve`;
export const REJECT_URL = id => `/approvals/${id}/reject`;

const adminApprovalService = {
  list: ({ page, limit, sort }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    return axios.get(url)
  },
  create: (data) => {
    return axios.post(CREATE_URL, data)
  },
  block: (data) => {
    return axios.post(BLOCK_URL(data.id), data)
  },
  companyBlock: (data) => {
    return axios.post(COMPANY_BLOCK_URL(data.id), data)
  },
  companyUnBlock: (data) => {
    return axios.post(COMPANY_UNBLOCK_URL(data.id), data)
  },
  detail: (id) => axios.get(DETAIL_URL(id)),
  approve: (id) => axios.post(APPROVE_URL(id)),
  reject: (id, data) => axios.post(REJECT_URL(id), data)
}

export default adminApprovalService
