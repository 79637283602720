import React from 'react'
import { faLock } from "@fortawesome/free-solid-svg-icons";
import RoundBadge from '../../components/Badge/RoundBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

const CorporateStatuses = ({ status = {}, forExcel }) => {
  const getPresume = () => (
    forExcel
      ? (status?.presume ? <span>{status.suspend}, </span> : <span>Tidak Diduga, </span>)
      :
      <RoundBadge
        title={status?.suspend ? "Terblokir": 'Tidak Terblokir'}
        active={status?.suspend}
        variant={"danger"}
        icon={<FontAwesomeIcon icon={faLock} size="xs" />}
      />
  );

  return (
    <div className="d-flex align-items-center justify-content-center">
      {getPresume()}
    </div>
  )
}

export default CorporateStatuses;