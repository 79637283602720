import React, { useState } from 'react'
import TextField from '../../../components/Forms/TextField';
import SimpleCard from '../../../components/templates/SimpleCard';
import styles from '../corporate.module.scss'
import corporateService from '../../../axios/services/corporateService';
import { format } from 'date-fns';
import FileCard from '../../../components/templates/FileCard';
import Button from '../../../components/Button';
import StatusHistory from '../../../components/StatusHistory';
import pakaiClass from 'pakai-class';
import RejectDialog from '../../../components/templates/RejectDialog';

const Supervisor = ({ item, getDetail, index}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const verifyId = () => {
    setIsLoading(true)
    corporateService.verifyId(item.user._id)
      .then(() => {
        getDetail();
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }
  const rejectCorporate = (data) => {
    setIsLoading(true)
    corporateService.reject(data, index)
      .then((res) => {
        getDetail()
        setShowDialog(false)
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <SimpleCard loading={isLoading} className="mb-24">
        <p className="font-500 mb-12">Informasi Manajer</p>
        <hr />
        <TextField
          className="mb-12"
          readOnly
          label="NIK"
          value={item.user.identificationNumber}
        />
        <TextField
          className="mb-12"
          readOnly
          label="Nama Lengkap"
          value={item.user.fullName}
        />
        <TextField
          className="mb-12"
          readOnly
          label="Email"
          value={item.user.email}
        />
        {/* <TextField
          className="mb-12"
          readOnly
          label="No. Hp"
          value={item.user.phoneNumber}
        /> */}
        <TextField
          className="mb-12"
          readOnly
          label="Jabatan"
          value={item.user.title}
        />
        <TextField
          className="mb-12"
          readOnly
          label="Departemen"
          value={item.user.department}
        />
        {
          item.user.status.updatedAt &&
          <>
            <TextField
              className="mb-12"
              readOnly
              label="Nomor Identifikasi"
              value={item.user.identificationNumber}
            />
            <TextField
              className="mb-12"
              readOnly
              label="Tempat Tanggal Lahir"
              value={`${item.user.placeOfBirth}, ${format(new Date(item.user.dateOfBirth), 'd MMMM yyyy')}`}
            />
            <TextField
              multiline
              className="mb-12"
              readOnly
              label="Alamat"
              value={item.user.address}
            />
            <div className="mt-32">
              <p className="font-500 mb-12">Berkas Manajer</p>
              <hr />
              <FileCard
                className="mb-12"
                title="Foto KTP"
                filename={item.user.identificationArchive.idCard.file.name}
                url={item.user.identificationArchive.idCard.file.url}
              />
              <FileCard
                className="mb-12"
                title="Foto Diri"
                filename={item.user.identificationArchive.selfPhoto.file.name}
                url={item.user.identificationArchive.selfPhoto.file.url}
              />
              <hr />
              <div style={{ display: item.user.identificationArchive.idCard.verifiedAt ?  "none": 'flex', justifyContent: "space-between"}} >
              <Button
                className={styles.verifyButton}
                onClick={verifyId}
              >
                Verifikasi Dukcapil
              </Button>
              <Button
                className={pakaiClass(styles.verifyButton, styles.rejected)}
                onClick={() => setShowDialog(true)}
              >
                Tolak
              </Button>
              </div>
            </div>
          </>
        }
      </SimpleCard>
      <RejectDialog
            show={showDialog}
            onClose={() => setShowDialog(false)}
            onCancel={() => setShowDialog(false)}
            onSubmit={rejectCorporate}
            modalTitle="Tolak Pengajuan Perusahaan"
            loading={isLoading}
          />
      <StatusHistory
        statusHistories={item.user.statusHistories}
      />
    </>
  )
}

export default Supervisor
