import React, { useState } from 'react'
import TextField from '../../../components/Forms/TextField';
import SimpleCard from '../../../components/templates/SimpleCard';
import FileCard from '../../../components/templates/FileCard';
import StatusHistory from '../../../components/StatusHistory';
import { StatusCard, StatusCardWrapper } from '../../../components/templates/StatusCard';
import Button from '../../../components/Button';
import pakaiClass from 'pakai-class';
import styles from '../corporate.module.scss'
import { Times } from '../../../assets/icons';
import VerificationAction from '../../../components/templates/VerificationAction';
import corporateService from '../../../axios/services/corporateService';
import RejectDialog from '../../../components/templates/RejectDialog';
import ApprovalCard from '../ApprovalCard';


const BasicInfo = ({ item, index, getDetail }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const verifyCorporate = () => {
    setIsLoading(true)
    corporateService.approve(index)
      .then((res) => {
        getDetail()
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  const rejectCorporate = (data) => {
    setIsLoading(true)
    corporateService.reject(data, index)
      .then((res) => {
        getDetail()
        setShowDialog(false)
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <SimpleCard className="mb-24" >
        <div>
          <TextField
            className="mb-12"
            readOnly
            label="Nama Perusahaan"
            value={item.company.name}
          />
          <TextField
            className="mb-12"
            readOnly
            label="Kode Perusahaan"
            value={item.company.code}
          />
          {
            item.company.bankAccountNumber && (
              <TextField
                className="mb-12"
                readOnly
                label="No Rek Bank"
                value={item.company.bankAccountNumber}
              />
            )
          }
          {
            item.company.bankAccountName && (
              <TextField
                className="mb-12"
                readOnly
                label="Nama Akun Bank"
                value={item.company.bankAccountName}
              />
            )
          }
          {
            item.company.bankAccountName && (
              <TextField
                className="mb-12"
                readOnly
                label="Nama Bank"
                value={item.company.bankName}
              />
            )
          }
          {
            item.company.badanUsaha && (
              <TextField
                className="mb-12"
                readOnly
                label="Badan Usaha"
                value={item.company.badanUsaha}
              />
            )
          }
          {
            item.company.department && (
              <TextField
                className="mb-12"
                readOnly
                label="Industri"
                value={item.company.department}
              />
            )
          }
        </div>
        {
          (item.company.archive.birthCertificate.file && item.company.archive.npwp.file && item.company.archive.siup && item.company.archive.sk.file)
          &&
          <div className="mt-32">
            <p className="font-500 mb-12 mt-32">Berkas Perusahaan</p>
            <hr />
            <FileCard
              className="mb-12"
              title="Akta pendirian perusahaan"
              filename={item.company.archive.birthCertificate.file.name}
              url={item.company.archive.birthCertificate.file.url}
            />
            <FileCard
              className="mb-12"
              title="NPWP perusahaan"
              filename={item.company.archive.npwp.file.name}
              url={item.company.archive.npwp.file.url}
            />
            <FileCard
              className="mb-12"
              title="NIB perusahaan"
              filename={item.company.archive.siup.file.name}
              url={item.company.archive.siup.file.url}
            />
            <FileCard
              className="mb-24"
              title="SK-kemenkumham"
              filename={item.company.archive.sk.file.name}
              url={item.company.archive.sk.file.url}
            />
          </div>
        }
      </SimpleCard>
      <SimpleCard className="mb-24" >
        <p className="font-500 mb-12">Step</p>
        <StatusCardWrapper>
          <StatusCard
            title="Form Telah diisi"
            value={item.company.status.updatedAt}
          />
          <StatusCard
            title="S&K disetujui"
            value={item.company.status.acceptTncAt}
          />
          <StatusCard
            title="Telah diverifikasi"
            value={item.company.status.verifiedAt}
          />
          {/* <StatusCard
          title="Paylater Activated"
          value={item.company.canPaylater}
        /> */}

        </StatusCardWrapper>
        {
          (item.company.status.acceptTncAt && item.company.status.updatedAt && !item.company.status.verifiedAt)
          &&
          <div className="mt-32">
            <p className="font-500 mb-12">Verifikasi Perusahaan</p>
            <hr />
            {
              !item.company.status.verifiedAt && item.company.status.rejectReason ?
                <div>
                  <div className="d-flex justify-content-between flex-wrap mb-16 font-size-14">
                    <p className="mr-16 text-dark-gray">Alasan Penolakan :</p>
                    <p>{item.company.status.rejectReason}</p>
                  </div>
                  <Button
                    disabled
                    className={pakaiClass(styles.verifyButton, styles.rejected)}
                  >
                    <Times />
                    <span>Pengajuan ditolak</span>
                  </Button>
                </div> :
                <VerificationAction
                  onApprove={verifyCorporate}
                  onReject={() => setShowDialog(true)}
                />
            }
            <RejectDialog
              show={showDialog}
              onClose={() => setShowDialog(false)}
              onCancel={() => setShowDialog(false)}
              onSubmit={rejectCorporate}
              modalTitle="Tolak Pengajuan Perusahaan"
              loading={isLoading}
            />
          </div>
        }
      </SimpleCard>
      <ApprovalCard
        title={item?.company?.status?.suspend ? "Un-Blokir" : "Blokir"}
        requestType={item?.company?.status?.suspend ? "USER_UNSUSPEND" : "USER_SUSPEND"}
        requestId={index}
        refresh={getDetail}
        variant="danger"
        message={item?.company?.status?.suspend ? "Akun ini ditangguhkan, Batalkan penangguhan" : "Klik untuk menangguhkan"}
      />
      <StatusHistory
        statusHistories={item.company.statusHistories}
      />
    </>
  )
}

export default BasicInfo
