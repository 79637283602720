import pakaiClass from 'pakai-class';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import adminApprovalService from '../../../axios/services/adminApprovalService';
import Button from '../../../components/Button';
import TextField from '../../../components/Forms/TextField';
import Modal, { ModalHead, ModalWrapper } from '../../../components/Modal';
import styles from './approvalCard.module.scss'

const ApprovalCard = ({
  title,
  requestType,
  requestId,
  refresh,
  variant,
  disabled,
  message
}) => {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const { push } = useHistory();

  const requestApproval = () => {
    if (requestType == "USER_SUSPEND") {
      const data = {
        id: requestId,
        category: requestType,
        description
      }
      adminApprovalService.block(data)
        .then(() => {
          setShow(false)
          refresh()
          toast("Blokir Berhasil", { type: 'success' });
        })
        .catch((e) => {
          setShow(false);
          toast(e.response.data.message, { type: 'error' });
        })
    } else {
      const data = {
        id: requestId,
        category: requestType,
        description
      }
      adminApprovalService.create(data)
        .then(() => {
          setShow(false)
          push('/user/admin-approval')
        })
        .catch((e) => {
          setShow(false);
          toast(e.response.data.message, { type: 'error' });
        })
    }
  }

  return (
    <>
      <button
        onClick={() => setShow(true)}
        className={pakaiClass(styles.approvalCard, styles[variant], disabled && styles.disabled)}
        disabled={disabled}
      >
        <div className="flex">
          {title}
          <h6 className="font-500">{message}</h6>
        </div>
      </button>
      <Modal onClose={() => setShow(false)} in={show}>
        <ModalWrapper>
          <ModalHead title="Kirim Permintaan Buka Blokir?" onClose={() => setShow(false)} />
          <TextField
            label="Deskripsi (Optional)"
            multiline
            className="mb-12"
            onChange={(e) => setDescription(e.target.value)}
          />
          <Button
            className={pakaiClass(styles.confirmButton, "mb-8")}
            onClick={requestApproval}
          >Kirim</Button>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default ApprovalCard
