import React, { useEffect, useMemo } from 'react'
import TextField from '../../components/Forms/TextField'
import { IMAGE_FORMATS } from '../../utils/helpers/fileFormats';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './banks.module.scss'
import MainButton from '../../components/templates/MainButton';
import ImageField from '../../components/Forms/ImageField';
import useAsync from '../../components/hooks/useAsync';
import bankService from '../../axios/services/bankService';
import { combineBy } from '../../utils/helpers/combineBy';
import SelectField from '../../components/Forms/SelectField';

const schema = yup.object().shape({
  // logo: yup.mixed()
  //   .when('$bankId', (bankId, schema) => (!bankId ? schema.test(
  //     "required",
  //     "Logo tidak boleh kosong",
  //     value => value.length > 0
  //   ) : schema))
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     value => value?.[0] ? IMAGE_FORMATS.includes(value[0].type) : true
  //   ),
  name: yup.string().optional('Nama Bank tidak boleh kosong'),
  code: yup.string().required('Kode Bank tidak boleh kosong'),
  abbr: yup.string().required('Tanggal lahir tidak boleh kosong'),
  artajasaCode: yup.string().required('Kode Artajasa tidak boleh kosong'),
  fee: yup.string().required('Fee tidak boleh kosong')
})

const Form = ({ onSubmit, initialValue, previousImageUrl, bankId }) => {

  const { register, handleSubmit, watch, unregister, setValue, formState: { isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialValue,
    context: { bankId },
  });

  const {
    pending: isGettingArtajasa,
    value: { data: { artajasaBank = [] } = {} } = {}
  } = useAsync(bankService.getArtajasa, true)

  const artajasaList = useMemo(() => artajasaBank.map(el => ({ label: combineBy([el.name, el.artajasaCode], ' - '), value: el.enum })), [artajasaBank])

  const {
    name,
    code,
    abbr,
    artajasaCode,
    fee
  } = watch(["name", "code", "abbr", "artajasaCode", "fee"])

  useEffect(() => {
    register('artajasaCode')
    return () => {
      unregister('artajasaCode')
    }
  }, [register, unregister])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div className="d-flex justify-content-center">
        <ImageField
          ref={register}
          className="mb-24"
          name="logo"
          previousImageUrl={previousImageUrl}
        />
      </div> */}
      <div className="row mb-12">
        <div className="col-md-6" style={{ display: "none"}}>
          <TextField
            label="Nama Bank"
            ref={register}
            value="Bank Name"
            name="name"
          />
        </div>
        <div className="col-md-12" style={{ display: "none"}}>
          <TextField
            label="Singkatan"
            ref={register}
            value="Singkatan"
            name="code"
          />
        </div>
      </div>
      <TextField
        className="mb-24"
        label="Kode Bank"
        ref={register}
        name="abbr"
      />
      <SelectField
        isLoading={isGettingArtajasa}
        value={artajasaCode}
        onChange={v => setValue('artajasaCode', v?.value, { shouldValidate: true })}
        options={artajasaList}
        label="Kode Artajasa"
        className="mb-24"
      />
      <TextField
        className="mb-24"
        label="Biaya"
        ref={register}
        name="fee"
      />
      <div className={styles.btnWrapper}>
        <MainButton
          className={styles.mainButton}
          type="submit"
          disabled={!(code && abbr && artajasaCode && fee && (bankId ? true : isValid))}
        >
          {bankId ? 'Edit' : 'Buat'}
        </MainButton>
      </div>
    </form>
  )
}

export default Form
