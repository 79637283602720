import React from 'react';
// import { format } from 'date-fns'
import { combineBy } from '../utils/helpers/combineBy';
import Timeline from './VerticalTimeline/Timeline';
import { StatusHistoryCard } from './templates/HistoryDialog/StatusHistoryCard';
import PopUp from './PopUp';
import MainButton from './templates/MainButton';
import { format } from 'date-fns';
import id from 'date-fns/locale/id'
import { AdminRole } from '../utils/enums/adminRoles';
const StatusHistory = (
  {
    show,
    setShow,
    withPopUp,
    statusHistories,
    warningType,
    successType,
    dangerType,
    mutateStatus,
    className
  }) => {

  const generateActor = (value) => {
    return (
      <b className="d-block font-500">
        Aktor:{combineBy([value.actor?.email || '-'])} [{AdminRole.getStr(value.actor.role ?? value.actorModel, value.actorModel == "User" && "Manajer")}]
      </b>
    )
  }

  const card = () => (
    <StatusHistoryCard
      className={className}
      title="Riwayat Pengguna"
      withFooter={
        withPopUp &&
        <Footer onClick={() => setShow(false)} />
      }
    >
      {statusHistories.map((s, k) => {
        return (
          <Timeline.Dot
            key={k}
            title={s.status}
            date={format(new Date(s.createdAt), 'dd MMM HH:mm:ss', "PP", {
              locale: id
            })}
            warningType={warningType}
            successType={successType}
            dangerType={dangerType}
            mutateStatus={mutateStatus}
            desc={generateActor(s)}
          />
        )
      })}
    </StatusHistoryCard>
  )

  return (
    withPopUp ?
      <PopUp
        in={show}
        onClose={() => setShow(false)}
      >
        {card()}
      </PopUp>
      :
      card()
  )
}

const Footer = ({ onClick }) => (
  <MainButton onClick={onClick} className="mt-16">Close</MainButton>
)

export default StatusHistory;
