import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Delete, Edit } from '../../assets/icons'
import TextField from '../../components/Forms/TextField'
import Table from '../../components/Table'
import ButtonIcon from '../../components/templates/ButtonIcon'
import SimpleCard from '../../components/templates/SimpleCard'
import { bankActions } from '../../redux/actions/bankActions'
import templateStyles from '../../components/templates/templates.module.scss'
import Modal, { ModalWrapper } from '../../components/Modal'
import Button from '../../components/Button'
import styles from './banks.module.scss'
import pakaiClass from 'pakai-class'
import bankService from '../../axios/services/bankService'

const MainTab = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter,
    }
  } = useSelector(state => state.banks);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(bankActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(bankActions.setTableConfig(key, value)),
    [dispatch],
  )

  const setTableConfigFilter = useCallback(
    key => value => dispatch(bankActions.setTableConfigFilter(key, value)),
    [dispatch],
  )

  useEffect(() => {
    load()
  }, [load])



  const deleteItem = () => {
    bankService.delete(selectedId)
      .then(() => {
        setShow(false)
        load()
      })
      .catch(() => { })
  }

  const columns = useMemo(() => {
    const showConfirmDelete = (id) => {
      setShow(true)
      setSelectedId(id);
    }
    return [
      {
        title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm')
      },
      // {
      //   title: 'Nama', key: 'name',
      // },
      // {
      //   title: 'singkatan', key: 'code',
      // },
      {
        title: 'Kode Bank', key: 'abbr',
      },
      {
        title: 'Kode artajasa', key: 'artajasaCode',
      },
      {
        title: 'Biaya', key: 'fee',
      },
      {
        title: '', key: '_id', render: (v, row) => (
          <div className={templateStyles.buttonGroup}>
            <ButtonIcon
              color="danger"
              icon={<Delete />}
              onClick={() => showConfirmDelete(v)}
            />
            <ButtonIcon
              color="edit"
              icon={<Edit />}
              onClick={() => dispatch(bankActions.createTab(v, row.name))}
            />
          </div>
        )
      }
    ]
  }, [dispatch]);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm') },
    { title: 'Name', key: 'name', },
    { title: 'Kode Bank', key: 'code', },
    { title: 'abbreviation', key: 'abbr', },
    { title: 'artajasa', key: 'artajasaCode', },
    { title: 'Biaya', key: 'fee', },
  ]

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Banks'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Bank`
          }}
        >
          <div className="row mb-12">
            <div className="col-md-3">
              <TextField
                variant="outlined"
                label="Cari"
                placeholder="Ketik Sesuatu..."
                value={filter.search}
                onChange={e => setTableConfigFilter('search')(e.target.value)}
              />
            </div>
          </div>
        </Table>
        <Modal onClose={() => setShow(false)} in={show}>
          <ModalWrapper>
            <div className="text-center mb-12">
              <h3>Apakah anda yakin ingin menghapus item ini?</h3>
            </div>
            <div>
              <Button
                className={pakaiClass(styles.confirmButton, "mb-8")}
                onClick={deleteItem}
              >Hapus</Button>
              <Button
                className={styles.cancelButton}
                onClick={() => setShow(false)}
              >Batal</Button>
            </div>
          </ModalWrapper>
        </Modal>
      </SimpleCard>
    </div>
  )
}

export default MainTab
